/*
 *  Document   : bootstrap.js
 *  Author     : pixelcave
 *  Description: Import global dependencies
 *
 */

// Import all vital core JS files..
import jQuery from 'jquery';
import SimpleBar from 'simplebar';
import Cookies from 'js-cookie';
import 'bootstrap';
import 'popper.js';
import 'jquery.appear';
import 'jquery-scroll-lock';
import Echo from 'laravel-echo';

// ..and assign to window the ones that need it
window.$ = window.jQuery = jQuery;
window.SimpleBar = SimpleBar;
window.Cookies = Cookies;
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    disableStats: true,
    key: process.env.MIX_PUSHER_APP_KEY,
    // cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsHost: process.env.MIX_PUSHER_APP_HOST,
    wsPort: process.env.MIX_PUSHER_APP_PORT,
    wssPort: process.env.MIX_PUSHER_APP_PORT,
    // forceTLS: false,
    encrypted:false,
    // enabledTransports: ['ws', 'wss'],
});
